import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { Observable, of } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CryptService } from 'src/app/services/crypt.service';
import { OurTeamService } from 'src/app/services/our-team/our-team.service';
import * as XLSX from 'xlsx';
import * as html2pdf from 'html2pdf.js';
import { ThemePalette } from '@angular/material/core';

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}

@Component({
  selector: 'app-showModels',
  templateUrl: './showModels.component.html',
  styleUrls: ['./showModels.component.css']
})
export class ShowModelsComponent implements OnInit {
  task: Task = {
    name: 'Indeterminate',
    completed: false,
    color: 'primary',
    subtasks: [
      { name: 'Primary', completed: false, color: 'primary' },
      { name: 'Accent', completed: false, color: 'accent' },
      { name: 'Warn', completed: false, color: 'warn' },
    ],
  };

  date: any = new Date();
  fileName = 'ExcelSheet.xlsx';
  indexFirst: number;

  selectedServices = '';
  selectedModels = '';

  ShowData: any = [];
  NoData = '';
  dataSourceModels: any = [];
  mainBrn: any[] = [];
  array: any = [];
  array2: any = [];
  Program: any = [];
  translate: 'yes';

  rowsPage: number;
  TableType: number;
  pageNumbers: any = [];
  LANG_ = this.c.Decrypt(sessionStorage.getItem('syslang'));
  BRN_ = this.c.Decrypt(sessionStorage.getItem('brnId'));
  TYPE_ = '';
  TYPE_2 = '';
  parentID = '';
  procName = '';
  TYPE_Name: any;
  NoRecords: boolean = false;
  showPanel: boolean = false;
  showUpdate: boolean = false;
  showDataPanle: boolean = false;
  showDataPanleUpdate: boolean = false;
  showcancel: boolean = false;
  showTableProd: boolean = false;
  loadPage = true;

  ModelNo = '';
  Email = '';

  NameOrder = '';
  Phone = '';
  MessageNJ = '';
  modelT: any = '';

  stringified: any;

  modelsCtrl = new FormControl();
  filteredModels: Observable<string[]>;

  modalRef: BsModalRef;
  id_delete: string;
  constructor(
    private c: CryptService,
    private getData: OurTeamService,
    private _Activatedroute: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private modalService: BsModalService
  ) {
    this._Activatedroute.paramMap.subscribe((params) => {
      this.TYPE_Name = this._Activatedroute.snapshot.paramMap.get('typeName');

      this.ngOnInit();
    });
  }

  myForm: FormGroup;

  //Function To Filtering Models Data
  private _filterModels(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.dataSourceModels.filter((state) =>
      state.postTitle.toLowerCase().includes(filterValue)
    );
  }
  searchText: string = '';
  btnclose() {
    this.showcancel = false;
    this.showDataPanle = false;
    this.showDataPanleUpdate = false;
    this.showUpdate = false;
    this.clearData();
    this.showTableProd = false;
  }
  btnUpdate() {
    this.showDataPanleUpdate = true;
    this.showcancel = true;
    this.showUpdate = true;

    this.showTableProd = true;
  }
  btnShow() {
    this.showDataPanle = true;
    this.showDataPanleUpdate = false;
    this.showcancel = true;
    this.showUpdate = false;

    this.showTableProd = true;
  }

  clearData(): void {
    this.NameOrder = '';
    this.Phone = '';
    this.Email = '';
    this.MessageNJ = '';
    this.modelT = '';
  }

  ngOnInit(): void {
    this.getRows('1');
    this.NoRecords = false;
    this.showDataPanle = false;
    this.showPanel = false;
   
    this.getDropDownModelsData();
    this.getData.getServices('sp','','','','','','','','','','').subscribe((data) => {
            this.ShowData = data;
            console.log("jh",this.ShowData);
            
          });
  }

  getRows(num) {
    if (this.rowsPage == null) this.rowsPage = 10;
    this.getData
      .viewPaging(
        num,
        this.rowsPage,
        this.BRN_,
        this.LANG_,
        this.TYPE_,
        'service',
        '',
        ''
      )
      .subscribe((data) => {
        this.ShowData = data['data1'];
        this.indexFirst = data['data1'][0]['displayOrder'];
        this.pageNumbers = data['data2'];
      });
    // this.ShowOrder = true
  }
  // BuildingModelCat realEstate
  getDropDownModelsData() {
    this.getData
      .getPost(
        'getPost',
        '',
        this.LANG_,
        this.BRN_,
        'BuildingModelCat',
        'NTROOT0',
        '',
        '-1'
      )
      .subscribe((data) => {
        this.dataSourceModels = data;
      });
  }
  openPanelOfModels() {
    this.filteredModels = this.modelsCtrl.valueChanges.pipe(
      startWith(''),
      map((reg) =>
        reg
          ? this._filterModels(reg)
          : this.dataSourceModels.slice()
      )
    );
  }
  getPage(event: any) {
    // this.ShowOrder = true

    this.rowsPage = event.target.value;
    this.getData
      .viewPaging(
        1,
        this.rowsPage,
        this.BRN_,
        this.LANG_,
        this.TYPE_,
        this.procName,
        '',
        ''
      )
      .subscribe((data) => {
        this.ShowData = data['data1'];
        this.pageNumbers = data['data2'];
      });
  }
  //Function To Export Table Data To Excel
  btnExport(): void {
    /* pass here the table id */
    let element = document.getElementById('excel_table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  getAllModel() {
    this.NoRecords = false;
    this.getData
      .getPost('getPost', '', this.LANG_, '', 'BuildingModelCat', '', '', '-1')
      .subscribe((data) => {
        this.ShowData = data;
      });
  }
  getAllModels() {
    this.NoRecords = false;
    this.getData
      .getServices(
        'sp',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        ''
      )
      .subscribe((data) => {
        this.ShowData = data;
      });
  }
  getValue(value: any) {
    this.selectedServices = value;
  }
  getModelValue(value: any) {
    this.selectedModels = value;
    
  }
  filtering(model: string) {
 
      this.getData
        .getServices('sp', '','','','','','','','','','')
        .subscribe((data) => {
          this.ShowData = data;
          let records = [];
          if ( model != undefined) {

            this.ShowData.filter(
              (record) => record.service === model 
            ).forEach((record) => records.push(record));
            this.ShowData = [];
            if (records.length == 0) {
              this.NoRecords = true;
            } else {
              this.NoRecords = false;
              records.forEach((el2) => {
                this.ShowData.push({
                  com_name: el2['com_name'],
                  com_phone: el2['com_phone'],
                  com_email: el2['com_email'],
                  service: el2['service'],
                  service_disc: el2['service_disc'],
                });
              });
            }
           } else {
            this.getAllModels();
          }
        });
    
  }

  showData(rowNumber: any) {
    this.loadPage = false;
    this.clearData();
    this.btnShow();
    this.getData
      .getServices(
        'sp',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        ''
      )
      .subscribe((data: any) => {
        this.ModelNo = data[rowNumber - 1]['reg_srv_id'];
        this.NameOrder = data[rowNumber - 1]['com_name'];
        this.Phone = data[rowNumber - 1]['com_phone'];
        this.Email = data[rowNumber - 1]['com_email'];
        this.MessageNJ = data[rowNumber - 1]['service_disc'];
        this.modelT = data[rowNumber - 1]['service'];

    
        this.loadPage = true;
      });
  }
  showDataToUpdate(rowNumber: any) {
    this.loadPage = false;
    this.clearData();
    this.btnUpdate();
    this.getData
      .getServices(
        'sp',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        ''
      )
      .subscribe((data: any) => {
        this.ModelNo = data[rowNumber - 1]['reg_srv_id'];
        this.NameOrder = data[rowNumber - 1]['com_name'];
        this.Phone = data[rowNumber - 1]['com_phone'];
        this.Email = data[rowNumber - 1]['com_email'];
        this.MessageNJ = data[rowNumber - 1]['service_disc'];
        this.modelT = data[rowNumber - 1]['service'];

    
        this.loadPage = true;
      });
  }
  update(): void {
    this.loadPage = false;
    this.showUpdate = false;
    this.showcancel = false;
    this.getData  
      .getServices('u', this.ModelNo,this.NameOrder,'',this.modelT,this.MessageNJ,this.Phone,this.Email,'','','' )
      .subscribe((data) => {
        this.getDataProd();
        this.clearData();
        this.btnclose();
        this._snackBar.open(data[0]['column1'], 'حسنا', {
          duration: 5000,
          horizontalPosition: 'left',
        });
        this.loadPage = true;
      });
  }

  getDataProd(): void {
    this.getRows('1');
    this.loadPage = true;
  }

  delete(template: TemplateRef<any>, id: any): void {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.id_delete = id;
  }

  confirm(): void {
    this.loadPage = false;
    this.getData
      .getServices(
        'd',
        this.id_delete,
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        ''
      )
      .subscribe((data) => {
        this.getDataProd();
        this.clearData();

        this._snackBar.open(data[0]['column1'], 'حسنا', {
          duration: 5000,
          horizontalPosition: 'left',
        });
        this.loadPage = true;
      });
    this.id_delete = '';
    this.modalRef.hide();
  }
  message = '';
  decline(): void {
    this.message = 'Declined!';
    this.modalRef.hide();
  }
  // @ViewChild('content') content: ElementRef;
  @ViewChild('childModal2') public childModal2: ModalDirective;

  public hideChildModal(): void {
    this.childModal2.hide();
  }
showPanelF(){
this.showPanel=true;
}
  download() {

    var element = document.getElementById('pdf_model_table');
    var opt = {
      margin: 1,
      filename: 'Order.pdf',
      html2canvas: { scale: 2 },
      image: { type: 'jpeg', quality: 0.98 },
      jsPDF: {
        unit: 'mm',
        format: 'A2',
        orientation: 'portrait',
        header: 'My Header',
      },
    };

    html2pdf().from(element).set(opt).save();
  }
}
